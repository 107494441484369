import React, { Component } from "react";
import { Link } from "gatsby";
import UserLinks from "../UserLinks/UserLinks";
import "./Footer.sass";
import logo from '../../../static/logos/logo.png'
import gambleAware from '../../img/begambleaware.png'

class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <div className="content has-text-centered">
          <img src={logo} alt="esportstips" className="footer-logo"/>
          <p>
            Latest esports tips and news for <b>esports betting.</b>
          </p>
        </div>
        <div className="level has-text-centered">
            <div className="level-item">
              <Link to="/esports-betting" className="footer-link">Esports betting</Link>
            </div>
            <div className="level-item">
              <Link to="/news" className="footer-link">News</Link>
            </div>
            <div className="level-item">
              <Link to="/games" className="footer-link">Games</Link>
            </div>
            <div className="level-item">
              <Link to="/betting-sites" className="footer-link">Betting sites</Link>
            </div>
        </div>
        <div className="content has-text-centered">
          <a href="https://www.begambleaware.org/">
            <img src={gambleAware} alt="esportstips" className="footer-logo"/>
          </a>
        </div>
      </footer>
    );
  }
}

export default Footer;
