import React, { Component } from "react";
import { Link } from "gatsby";
import Img from "gatsby-image"
import "./BetSiteListing.sass"
import BetCardRating from "../Elements/BetCardRating/BetCardRating.jsx"

class BetSiteListing extends Component {
  getColor (value) {
    const hue = Math.round(value);
    return ["hsl(", hue, ", 50%, 50%)"].join("");
  }
  render() {
    const sites = this.props.sites;
    return (
        <div className="columns is-0 is-mobile is-multiline">
            {
            sites.map((item, index) => {
                const buttonStyle = {
                  backgroundColor: item.node.data.brand_color,
                  minWidth: "50%",
                  textTransform: "uppercase",
                  fontSize: "0.9rem"
                }
                const ratingStyle = {
                  backgroundColor: this.getColor(item.node.data.rating)
                }
                const topContainerStyle = {
                  display: 'flex',
                  justifyContent: 'space-between',
                  aligntItems: 'center'
                }
                return (
                <div key={index} className="column">
                    <div className="box bet-site-card">
                    <div className="level is-mobile">
                      <div className="level-left">
                        <div className="level-item">
                          <Link to={"/betting-sites/" + item.node.uid}>
                            <div className="bet-site-logo">
                              <Img className="bet-site-card-image" fluid={ item.node.data.logo.localFile.childImageSharp.fluid } alt={ item.node.uid } />
                              </div>
                          </Link>
                        </div>
                      </div>
                      <div className="level-right">
                        <div className="level-item">
                          <BetCardRating rating={item.node.data.rating}/>
                        </div>
                      </div>
                    </div>
                    <div className="level">
                      <div className="level-item">
                        <a href={'/go/' + item.node.uid} rel="nofollow" target="_blank" style={buttonStyle} className="button is-rounded is-dark">{item.node.data.bonus}</a>
                      </div>
                    </div>
                    <div className="level has-text-centered">
                      <div className="level-item bet-site-card-tc">
                        <small>New customers only. 18+, <a className="tc-link" href={item.node.data.terms_and_conditions_link.url} rel="nofollow" target="_blank">T&Cs apply.</a></small>
                        </div>
                      </div>
                    </div>
                </div>
                )
            })}
        </div>
    );
  }
}

export default BetSiteListing;
