import React from "react";
import Helmet from "react-helmet";
import config from "../../data/SiteConfig";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import "./index.sass";

export default class MainLayout extends React.Component {
  openTab = (evt, tabName) => {
    var i, x, tablinks;
    x = document.getElementsByClassName("content-tab");
    for (i = 0; i < x.length; i++) {
        x[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tab");
    for (i = 0; i < x.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" is-active", "");
    }
    document.getElementById(tabName).style.display = "block";
    evt.currentTarget.className += " is-active";
  }

  render() {
    const { children } = this.props;
    return (
      <div className="layout">
        <Helmet>
                <title>{`${config.siteTitle}`}</title>
                <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon" />
                <link rel="icon" href="/favicon.ico" type="image/x-icon" />
                <meta name="google-site-verification" content="uc0WBLhsNGCi2qUilghGEpmcN4MXgod0KwIrRFnnh-k" />
                <meta name="description" content={config.siteDescription} />
                <meta name="image" content={config.siteLogo} />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta property="og:url" content={config.siteUrl} />
                <meta property="og:title" content={config.siteTitle} />
                <meta property="og:description" content={config.siteDescription} />
                <meta property="og:image" content={config.siteLogo} />
                {/* <meta property="fb:app_id" content={config.siteFBAppID ? config.siteFBAppID : ""}/> */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:creator" content={config.userTwitter ? config.userTwitter : ""}/>
                <meta name="twitter:title" content={config.siteTitle} />
                <meta name="twitter:description" content={config.siteDescription} />
                <meta name="twitter:image" content={config.siteLogo} />
        </Helmet>
        <Navbar/>
          { children }
        <Footer/>
      </div>
    );
  }
}
