import React, {Component} from "react";
import { Link } from "gatsby";
import Img from "gatsby-image"
import "./Faq.sass"

class Faq extends Component {
  render() {
    const faqItems = this.props.faqItems;
    return (
            <div>
                <div className="box faq-box">
                    <h3 className="subtitle">FAQ</h3>
                    {
                    faqItems.map((item, index) => {
                        return (
                            <div key={index} className="faq-item">
                                <h4 className="faq-item-title">
                                    <div className="faq-item-title-text">
                                        <span className="icon success">
                                            <i className="material-icons">help_outline</i>
                                        </span>
                                            {item.faq_title.text}
                                    </div>
                                </h4>
                                {/* <hr className="faq-hr"></hr> */}

                                <div className="faq-item-body">
                                {item.faq_content.text}
                                </div>
                                {/* <hr className="faq-hr"></hr> */}
                            </div>
                        )
                    })}
                </div>
            </div>
    );
  }
}

export default Faq;
