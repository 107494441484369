import React, { Component } from "react";
import _ from "lodash";
import { Link } from "gatsby";
import classnames from 'classnames';
import './Navbar.sass'
import logo from '../../../static/logos/logo.png'

class Navbar extends Component {
    toggleNavbar = () => {
        let navbarMenu = document.getElementById("navbarMenu")
        let navbarBurger = document.getElementById("navbarBurger")
        if (navbarMenu.classList.contains("is-active")) {
            navbarMenu.classList.remove('is-active')
            navbarBurger.classList.remove('is-active')
        } else {
            navbarMenu.classList.add('is-active')
            navbarBurger.classList.add('is-active')
        }
    }
    render () {
        return (
            <nav className="navbar" id="navbar" role="navigation" aria-label="main navigation">
                <div className="navbar-brand">
                    <div className="navbar-item">
                        <Link to="/">
                            <div className="navbar-logo">
                                <img src={logo} alt="esportstips" />
                            </div>
                        </Link>
                    </div>
                    <a id="navbarBurger" className="navbar-burger burger"
                    aria-label="menu" aria-expanded="false" data-target="navbarBasicExample" onClick={this.toggleNavbar}>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </a>
                </div>
                <div id="navbarMenu" className="navbar-menu">
                    <div className="navbar-start">
                    </div>
                    <div className="navbar-end">
                        <Link className="navbar-item" to="/news">
                        News
                        </Link>
                        <Link className="navbar-item" to="/games">
                        Games
                        </Link>
                        <Link className="navbar-item" to="/betting-sites">
                        Betting sites
                        </Link>
                    </div>
                </div>
            </nav>
        )
    }
}

export default Navbar
