module.exports = {
  siteTitle: "The best esports betting sites, tips and news - esportstips.net", // Site title.
  siteTitleShort: "Esportstips", // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteTitleAlt: "The best esports tips, reviews and guides for esports betting", // Alternative site title for SEO.
  siteDescription: "esportstips.net provides you with the latest reviews of esports bettings sites and news and tips related to esports.", // Website description used for RSS feeds/meta description tag.
  googleAnalyticsID: "UA-136658082-1", // GA tracking ID.
  siteLogo: "/logos/meta_logo.jpg", // Logo used for SEO and manifest.
  siteUrl: "https://esportstips.net", // Domain of your website without pathPrefix.
  pathPrefix: "/esportstips", // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
  siteFBAppID: "1825356251115265", // FB Application ID for using app insights
  dateFromFormat: "YYYY-MM-DD", // Date format used in the frontmatter.
  dateFormat: "DD/MM/YYYY", // Date format for display.
  themeColor: "#242424", // Used for setting manifest and progress theme colors.
  backgroundColor: "#242424" // Used for setting manifest background color.
};
