import React, { Component } from "react";
import { Link } from "gatsby";
import Img from "gatsby-image"
// import "./BetBonusListing.sass"
import BetCardRating from "../Elements/BetCardRating/BetCardRating.jsx"

class BetBonusListing2 extends Component {
  render() {
    const sites = this.props.sites;
    return (
        <div className="columns is-table is-multiline">
            {
            sites.map((item, index) => {
                const buttonStyle = {
                  backgroundColor: item.node.data.brand_color,
                  minWidth: "100%",
                  textTransform: "uppercase",
                }
                return (
                <div key={index} className="column is-one-fifhts">
                    <div className="box site-box bet-site-card bet-bonus-card">
                      <div className="bet-site-card-header">
                        <Link to={"/betting-sites/" + item.node.uid}>
                          <div className="bet-bonus-logo" style={{marginRight: '0.33rem'}}>
                            <Img className="bet-site-card-image" fluid={ item.node.data.logo.localFile.childImageSharp.fluid } alt={ item.node.uid } />
                          </div>
                        </Link>
                        <BetCardRating rating={item.node.data.rating}/>
                      </div>
                      <div className="bet-site-card-body">
                      </div>
                      <div className="bet-site-card-footer">
                        <small className="bet-site-card-tc">New customers only. 18+, <a className="tc-link" href={item.node.data.terms_and_conditions_link.url} rel="nofollow" target="_blank">T&Cs apply.</a></small>
                      </div>
                        {/* <span className="bet-bonus-text">{item.node.data.bonus}</span> */}
                        {/* {item.node.data.name.text} */}
                        {/* <div className="striped-line"></div> */}
                    </div>
                    <div className="bet-bonus-claim-button">
                        <a href={'/go/' + item.node.uid} rel="nofollow" target="_blank" className="button is-rounded is-info">Bet</a>
                    </div>
                </div>
                )
            })}
        </div>
    );
  }
}

export default BetBonusListing2;
