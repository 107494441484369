import React from "react";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../layout";
import config from "../../data/SiteConfig";
import Img from "gatsby-image"
import BetBonusListing from '../components/BetBonusListing/BetBonusListing'
import BetSiteListing from '../components/BetSiteListing/BetSiteListing'
import BetSiteListing2 from '../components/BetSiteListing2/BetSiteListing2'
import Faq from "../components/Faq/Faq"
import './news.sass'


class betsites extends React.Component {
  render () {
    const betSites = this.props.data.allPrismicBetsite.edges
    const featuredBetSites = this.props.data.allPrismicBetsite.edges.slice(0, 2)
    const content = this.props.data.prismicBettingSites.data

    const metaTitle = "The best esports betting sites and bonuses"
    const metaDescription = "We cover the hottest esports betting sites which enable you to bet on your favorite esports games and matches."
    return (
      <Layout>
        <div className="index-container">
        <Helmet>
          <title>{`${metaTitle}`}</title>
          <meta name="description" content={metaDescription} />
          <meta name="image" content={config.siteLogo} />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta property="og:url" content={this.props.location.href} />
          <meta property="og:title" content={metaTitle} />
          <meta property="og:description" content={metaDescription} />
          <meta property="og:image" content={config.siteLogo} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:creator" content={config.userTwitter ? config.userTwitter : ""}/>
          <meta name="twitter:title" content={metaTitle} />
          <meta name="twitter:description" content={metaDescription} />
          <meta name="twitter:image" content={config.siteLogo} />
        </Helmet>
          <section className="section">
            <div>
              <Img fluid={ content.image.localFile.childImageSharp.fluid }
              style={{position: 'absolute', left: '0', top: '0', width: '100%', height: '100%'}}/>
              <div className="bg-tint-2"></div>
            </div>
            <div className="container">
              <div className="columns">
                <div className="column">
                  <h1 className="title">
                    { content.title.text }
                  </h1>
                  <div dangerouslySetInnerHTML={{ __html: content.content.html }} />
                  <Link to="/esports-betting">
                    <button className="button is-info is-small">Learn more about esports betting</button>
                  </Link>
                </div>
                <div className="column is-one-fourth">
                  <BetSiteListing sites={featuredBetSites} className="bet-site-listing" />
                </div>
              </div>
            </div>
          </section>
          <section className="section content-section">
            <div className="container">
              <h2 className="subtitle">
                { content.content_blocks[0].content_title.text }
              </h2>
              <div dangerouslySetInnerHTML={{ __html: content.content_blocks[0].content_text.html }} />
            </div>
          </section>
          <section className="section">
              <div className="container">
                <BetSiteListing2 sites={betSites} className="bet-site-listing" />
              </div>
          </section>
          <section className="section">
                <div className="container">
                  <h2 className="subtitle">
                    { content.content_blocks[1].content_title.text }
                  </h2>
                  <div dangerouslySetInnerHTML={{ __html: content.content_blocks[1].content_text.html }} />
                </div>
          </section>
          <section className="section">
              <div className="container">
                <BetBonusListing sites={betSites} className="bet-site-listing" />
              </div>
          </section>
          <section className="section">
                <div className="container">
                  <h2 className="subtitle">
                    { content.content_blocks[2].content_title.text }
                  </h2>
                  <div className="has-list-items" dangerouslySetInnerHTML={{ __html: content.content_blocks[2].content_text.html }} />
                </div>
          </section>
          <section className="section">
            <div className="container">
              <Faq faqItems={content.faq} />
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}

export default betsites;

export const pageQuery = graphql`
  query BettingSitesQuery {
    allPrismicBetsite {
      edges {
        node {
          uid
          data {
            name {
              text
            }
            website {
              url
            }
            terms_and_conditions_link {
              url
            }
            bonus
            rating
            brand_color
            logo {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
		prismicBettingSites {
      data {
        title {
          text
        }
        content {
          html
        }
        image {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        content_blocks {
          content_title {
            text
          }
          content_text {
            html
          }
        }
        faq {
          faq_title {
            text
          }
          faq_content {
            text
          }
        }
      }
    }
  }
`;
