import React, { Component } from "react";
import "./BetCardRating.sass"

class BetCardRating extends Component {
  getColor (value) {
    const hue = Math.round(value);
    return ["hsl(", hue, ", 50%, 50%)"].join("");
  }
  render() {
    const rating = this.props.rating;
    const ratingStyle = {
      backgroundColor: this.getColor(rating),
      boxShadow: '0 0px 3px 0px ' + this.getColor(rating)
    }
    return (
      <div className="bet-card-rating">
        <span className="bet-card-rating-icon" style={ratingStyle}>{rating}</span>
      </div>
    );
  }
}

export default BetCardRating;
