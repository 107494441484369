import React, { Component } from "react";
import { Link } from "gatsby";
import Img from "gatsby-image"
import "./BetBonusListing.sass"

class BetBonusListing extends Component {
  render() {
    const sites = this.props.sites;
    return (
        <div className="columns is-table is-multiline">
            {
            sites.map((item, index) => {
                const buttonStyle = {
                  backgroundColor: item.node.data.brand_color,
                  minWidth: "100%",
                  textTransform: "uppercase",
                }
                return (
                <div key={index} className="column is-one-quarter">
                    <div className="box bet-site-card">
                      <div className="bet-site-card-header">
                        <Link to={"/betting-sites/" + item.node.uid}>
                          <div className="bet-bonus-logo">
                            <Img className="bet-site-card-image" fluid={ item.node.data.logo.localFile.childImageSharp.fluid } alt={ item.node.uid } />
                          </div>
                        </Link>
                      </div>
                        <div className="bet-site-card-body">
                          <span className="bet-bonus-text">{item.node.data.bonus}</span>
                        </div>
                      <div className="bet-site-card-footer">
                        {/* <div className="striped-line" style={{marginTop: '-2rem'}}></div> */}
                        <small>New customers only. 18+, <a className="tc-link" href={item.node.data.terms_and_conditions_link.url} rel="nofollow" target="_blank">T&Cs apply.</a></small>
                      </div>
                    </div>
                    <div className="bet-bonus-claim-button">
                        <a href={'/go/' + item.node.uid} rel="nofollow" target="_blank" className="button is-rounded is-info">Claim bonus</a>
                    </div>
                </div>
                )
            })}
        </div>
    );
  }
}

export default BetBonusListing;
